
import ApiService from "@/core/services/ApiService";

import Quill from "quill";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import PostWidget from "@/components/widgets/feeds/Widget1.vue";
import HacktivityWidget from "@/components/widgets/feeds/Widget6.vue";
import { defineComponent, computed, ref, onMounted} from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "leaderboard-component",
  data() {
    return {
       vpn: {
        ip: "",
        online: false
        }
    }
    },
    components: {
        PostWidget,
        HacktivityWidget,
    },
    methods: {
        async loadMore(){
           
            if(this.loading){
                return;
            } 
            if(this.store.getters.getFeedEnded){
                   
                    return true;
            }
            await this.store.dispatch(Actions.GET_ALL_FEEDS);
           
        },
    },
    mounted() {
        window.addEventListener("scroll", () => {
               
        
                if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
                    if(!this.loading){
                    
                        this.loadMore();
                    }
                }
            });
    },
    setup() {
      const store = useStore();
      const scoreboard = ref([]);
      store.commit(Mutations.SET_FEED, {});
      store.commit(Mutations.REFRESH_PAGINATION);

      store.dispatch(Actions.GET_ALL_FEEDS);

      const feeds = computed(() => {
        return store.getters.getFeed;
      });

      const loading = computed(() => {
        return store.getters.getIsLoading;
      });

      const user = computed(() => {
        return store.getters.currentUser;
      });
        
      return {
        user,
        scoreboard,
        moment,
        feeds,
        store,
        loading
      };
    },
});
